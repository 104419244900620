:root {
	--red: #b41f23;
	--darkRed: #971a1e;
	--white: #ffffff;
	--black: #000000;
	--orange: #e49815;
	--green: #69b41f;
	--darkGray: #707070;
	--mediumGray: #bcbcbc;
	--lightGray: #e9e9e9;
	--lightBG: #f7f4f1;
	--mediumBG: #efe9e3;
	--lightBlueBG: #ccf8ff;
	--lightYellowBG: #fff7c5;
	white-space: pre-line;
}
/* 
How to create linebreak 
https://stackoverflow.com/questions/45856051/react-i18n-break-lines-in-json-string
*/

*,
*::before,
*::after {
	box-sizing: border-box;
}

html {
	font-size: 16px;
}

body {
	font-family: brandon-grotesque, sans-serif;
	font-weight: 400;
	font-style: normal;
	margin: 0;
}

.verti-hori-center {
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	position: absolute;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: garamond-premier-pro, serif;
	font-weight: 400;
	font-style: normal;
}

h1 > .italic,
h2 > .italic,
h3 > .italic {
	font-family: garamond-premier-pro, serif;
	font-weight: 400;
	font-style: italic;
}

button:disabled {
    color: #fff;
    cursor: not-allowed;
    opacity: 0.4;
}

.txtCenter {
	text-align: center;
}

.txtRight {
	text-align: right;
}

.underline {
	border-bottom: 1px solid var(--black);
}

input,
select,
.input,
textarea {
	color: var(--darkGray);
	border: 1px solid var(--darkGray);
	padding-left: 10px;
	padding-right: 10px;

	font-family: brandon-grotesque, sans-serif;
	font-weight: 400;
	font-style: normal;
	font-size: 1rem;

	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;

	outline: none !important;
}

input:not(:read-only):active,
input:not(:read-only):hover,
input:not(:read-only):focus,
input:not(:read-only):focus-visible,
select:not(:read-only):active,
select:not(:read-only):hover,
select:not(:read-only):focus,
select:not(:read-only):focus-visible,
.input:not(:read-only):active,
.input:not(:read-only):hover,
.input:not(:read-only):focus,
.input:not(:read-only):focus-visible,
textarea:not(:read-only):active,
textarea:not(:read-only):hover,
textarea:not(:read-only):focus,
textarea:not(:read-only):focus-visible {
	border: 1px solid var(--darkRed);
	box-shadow: none;
	outline: none !important;
}

input:read-only:hover {
	cursor: default;
	border-color: var(--black);
}

select {
	outline: none !important;
}

select::-ms-expand {
	display: none;
}

a {
	color: var(--black);
	text-decoration: none;
}

.breadcrumbs > *{
	padding: 0px 3px;
}

/*.breadcrumbs .txt:first-of-type {
	padding: 0px 3px 0px 0px;
}*/

.breadcrumbs > :first-child {
	padding: 0px 3px 0px 0px;
}

.breadcrumbs a {
	color: var(--darkGray);
	padding: 0px 3px;
}

.breadcrumbs {
	text-transform: uppercase;
	font-size: 13px;
	color: var(--darkGray);
}

a:hover {
	color: var(--darkRed);
	cursor: pointer;
}

.additionalMusician {
	background-color: var(--lightBlueBG);
}

.regularMusician {
	background-color: var(--lightYellowBG);
}

.hover:hover {
	cursor: pointer;
}

/*
FONTS

Garamond Premier Pro Regular

font-family: garamond-premier-pro, serif;
font-weight: 400;
font-style: normal;



Garamond Premier Pro Italic

font-family: garamond-premier-pro, serif;
font-weight: 400;
font-style: italic;


Brandon Grotesque Regular

font-family: brandon-grotesque, sans-serif;
font-weight: 400;
font-style: normal;


Brandon Grotesque Black

font-family: brandon-grotesque, sans-serif;
font-weight: 900;
font-style: normal;
*/


ul.dashedList, ul.noListStyle {
	padding-left: 10px;
	list-style-type: none;
}

ul.dashedList {
	text-indent: -17px;
}

ul.dashedList > li {
	padding-left: 10px;
}

ul.dashedList > li:before {
	content: "-";
	padding-right: 10px;
}

.txt_vertical_center {
	vertical-align: middle;
}

.dataInput {
	box-sizing: border-box;
	width: 100%;
	margin: 5px 0px;
	border-radius: 0;
	padding: 10px;
}

.mainMenu a {
	color: var(--white);
}

/* Old option to show sub-menu on hover 
.mainMenu li:hover > .subMenu {
	display: block;
}
*/
.mainMenu li > .subMenu {
	display: block;
}

.react-time-input-picker-wrapper {
	display: block;
	width: 100%;
}

.react-time-input-picker-wrapper .inputWrapper {
	width: 50%;
}

.react-time-input-picker-wrapper .inputWrapper input {
	width: 100%;
}

.react-time-input-picker-wrapper .inputWrapper input:focus {
	background-color: var(--lightBG);
}